@import './variables.css';

main, html, #root {
  margin: 0;
  height: 100%;
  font-family: var(--font-family);
}

/* Header - barre menus */
.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.App-header {
  display: flex;
  justify-content: space-between; /* Distributes space between logo/text and buttons */
  align-items: center; /* Centers the content vertically */
  height: 60px; /* Fixed height for the header */
  background-color: var(--background-color);
  color: var(--text-color);
  width: 100%;
  padding: 10px 20px; /* Padding for consistent spacing */
}

.logo {
  display: flex;
  align-items: center;
}

.name {
  font-size: larger;
  font-weight: bold;
}

.phone {
  font-size: larger;
  display: flex;
  margin-left: 0;
  margin-right: 3%;
  align-items: center;
  color: var(--secondary-color);
  font-weight: bold;
  text-decoration: none;
}

.App-logo {
  height: 60px; /* Adjust height to fit within the 60px header */
  pointer-events: none;
  margin-right: 10px; /* Adds some space between the image and the text */
}

.buttons {
  display: flex;
  gap: 10px; /* Adds space between the buttons */
}

.App-body {
  flex: 1; /* Takes the remaining space */
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 20px; /* Optional padding for the body content */
}

/* First block - presentation */
.logo-full {
  height: 200px; /* Adjust height to fit within the 100px header */
  pointer-events: none;
  align-items: center;
  justify-content: center;
  }
  
  .presentation {
  background-color: var(--secondary-color);
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
  color: white;
}

.presentation img {
  margin-top: 20px;
  width: 200px; /* Adjust width to fit within the 100px header */
}

.presentation p {
  font-size: 20px;
  text-align: center;
  margin: 0 20px 10px 20px; /* Adjusted margins for responsiveness */
}

/* Second block - services */
.services {
  display: flex;
  justify-content: space-between;
  gap: 20px; /* Adds space between the services */
  margin: 2%;
  margin-top: 30px;
}

.service img {
  margin-bottom: 10px; /* Adjust width to fit within the 100px header */
}

.service {
  flex: 0 1 calc(33.33% - 20px); /* Adjust flex properties */
  max-width: calc(33.33% - 20px); /* Set maximum width to ensure items do not exceed 1/3 of container */
  text-align: center; /* Center-align text */
  padding: 10px;
}

/* Third block - situations */
.situations {
  margin: 30px; /* Adds space between the situations */
}

.situations-q {
  font-size: 200%; /* Adjust font-size to fit within the 100px header */
  margin-left: 10px;
  font-weight: bold;
}

.situations-p {
  margin-top: 20px;
  font-size: 100%; /* Adjust font-size to fit within the 100px header */
  margin-left: 10px;
}

/* Fourth block - processus */
.processus {
  margin-left: 30px;
}

/* Fifth block - contacts */
.contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px; /* Adds space between the contact info */
  margin: 30px;
  background-color: var(--secondary-color);
  border-radius: 50px;
  color: white;
}

.contact-info p {
  font-size: 16px; /* Adjust font-size to fit within the 100px header */
  margin: 0;
}

.contact-info a {
  font-size: 16px; /* Adjust font-size to fit within the 100px header */
  margin: 0;
  text-decoration: none;
  color: white;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .App-header {
    flex-direction: column;
    align-items: center;
    height: auto; /* Adjust height for mobile */
  }

  .phone {
    margin-left: 0;
    margin-right: 0;
  }

  .logo, .buttons {
    width: 100%;
    justify-content: center;
  }

  .logo img {
    margin-right: 0;
  }

  .presentation p {
    font-size: 16px; /* Smaller font size for mobile */
    margin: 0 10px 10px 10px;
  }

  .services {
    flex-direction: column;
    align-items: center;
  }

  .service {
    flex: 1 1 100%;
    max-width: 100%; /* Full width on mobile */
  }

  .situations {
    margin: 20px 10px; /* Reduced margins for mobile */
  }

  .contact-info {
    margin: 20px 10px; /* Reduced margins for mobile */
  }

  .contact-info p, .contact-info a {
    font-size: 14px;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 10px 0 10px; /* Smaller font size for mobile */
  }
}

@media (max-width: 480px) {
  .App-header {
    padding: 10px;
  }

  .App-logo {
    height: 40px; /* Smaller logo height for mobile */
  }

  p {
    font-size: 12px; /* Smaller font size for mobile */
  }

  .presentation img {
    width: 150px; /* Smaller image width for mobile */
  }

  .presentation p {
    font-size: 14px; /* Smaller font size for mobile */
  }

  .situations p {
    font-size: 14px; /* Smaller font size for mobile */
  }
}
