/* src/components/ImageCarousel.css */

.image-carousel-container {
  max-width: 50%; /* Set the maximum width of the carousel to 50% of the page width */
  margin: 20px auto; /* Center the carousel within its container */
}

.carousel .slide {
  background: none; /* Remove the default background */
}

.carousel-image {
  max-width: 100%; /* Ensure images take the full width of the container */
  max-height: 400px; /* Limit maximum height to 400px */
  width: auto; /* Ensure images scale proportionally */
  height: auto; /* Ensure images scale proportionally */
  object-fit: contain; /* Maintain aspect ratio and fit within container */
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .image-carousel-container {
    max-width: 90%; /* Adjust max width for tablets and smaller screens */
    margin: 10px auto; /* Adjust margins for smaller screens */
  }

  .carousel-image {
    max-height: 300px; /* Smaller height for smaller screens */
  }
}

@media (max-width: 480px) {
  .image-carousel-container {
    max-width: 95%; /* Adjust max width for mobile screens */
    margin: 5px auto; /* Adjust margins for mobile screens */
  }

  .carousel-image {
    max-height: 200px; /* Smaller height for mobile screens */
  }
}
