@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

:root {
    --primary-color: #131527;
    --secondary-color: #1E7DBB;
    --background-color: white;
    --text-color: #131527;
    --font-size: 16px;
    --font-family: 'Poppins', sans-serif;
}
  